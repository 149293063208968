<template>
  <div id="Help">

    <v-container
      grid-list-xl
      fluid
      class="card-jumpto"
    >
      <v-layout wrap>
        <v-flex xs12>
          <v-card text-align-right>
            <v-card-actions>
              <v-list dense>
                <v-subheader style="margin-bottom: 0.5rem;">Jump to:</v-subheader>
                <v-list-group
                  v-model="jumptoopen"
                  class="group-jumpto"
                >
                  <v-list-tile
                    class="jumpto-list"
                  >
                    <v-list-tile-content>                      
                      <v-list-tile-title
                        v-if="viewEntityPermission && updateEntityPermission"
                        class="item-jumpto"
                        @click="gotoanchor('help-manageusers')"
                      >
                        User Management
                      </v-list-tile-title>
                      <v-list-tile-title
                        class="item-jumpto"
                        @click="gotoanchor('help-accountsettings')"
                      >
                        Account Settings
                      </v-list-tile-title>
                      <v-list-tile-title
                        v-if="viewEntityPermission && createEntityPermission"
                        class="item-jumpto"
                        @click="gotoanchor('help-createusers')"
                      >
                        Creating Users
                      </v-list-tile-title>
                      <v-list-tile-title
                        v-if="createCustomerPermission || uploadDashboardImage || deleteEntityPermission"
                        class="item-jumpto"
                        @click="gotoanchor('help-support')"
                      >
                        Support
                      </v-list-tile-title>
                      <v-list-tile-title
                        v-if="viewPermissionPermission"
                        class="item-jumpto"
                        @click="gotoanchor('help-managepermissions')"
                      >
                        Managing Permissions
                      </v-list-tile-title>
                      <v-list-tile-title
                        v-if="viewRolePermission"
                        class="item-jumpto"
                        @click="gotoanchor('help-manageroles')"
                      >
                        Managing Roles
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list-group>
              </v-list>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container
      grid-list-xl
      fluid
    >
      <v-layout wrap>
        <v-flex xs12>
          <v-card class="white">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  id="helpSearchBar"
                  v-model="search"
                  label="What are you looking for?"
                  class="style-search searchbar"
                  prepend-icon="mdi-magnify"
                  clearable
                  single-line
                  hide-details
                />
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container
      id="general"
      ref="helpcontainer"
      grid-list-xl
      fluid
      class="container-margin"
    >
      <HelpManageUsers
        v-if="viewEntityPermission && updateEntityPermission"
      />
      <HelpAccountSettings />
      <HelpCreateUsers
        v-if="viewEntityPermission && createEntityPermission"
      />
      <HelpSupport
        v-if="createCustomerPermission || uploadDashboardImage || deleteEntityPermission"
      />
      <HelpManagePermissions
        v-if="viewPermissionPermission"
      />
      <HelpManageRoles
        v-if="viewRolePermission"
      />
    </v-container>
    <v-fade-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        id="compTasksScrollBtn"
        transition="scale-transition"
        fab
        fixed
        bottom
        right
        style="z-index:101;"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-fade-transition>
  </div>
</template>

<script>
import HelpCreateUsers from '../components/help/HelpCreateUsers'
import HelpManageCustomers from '../components/help/HelpManageCustomers'
import HelpAccountSettings from '../components/help/HelpAccountSettings'
import HelpManageUsers from '../components/help/HelpManageUsers'
import HelpSupport from '../components/help/HelpSupport'
import HelpManageRoles from '../components/help/HelpManageRoles'
import HelpManagePermissions from '../components/help/HelpManagePermissions'

export default {
  name: 'Help',
  metaInfo: {
    title: 'iQuote - Help Page'
  },
  components: {
    HelpCreateUsers,
    HelpManageCustomers,
    HelpAccountSettings,
    HelpManageUsers,
    HelpSupport,
    HelpManageRoles,
    HelpManagePermissions   
  },
  data: () => ({
    search: '',
    jumptoopen: false,
    fab: false
  }),
  computed: {
    hasStorm () {
      return this.$store.getters.stormCompany.companyId !== undefined
    },
    viewCustomerPermission () {
      return this.$store.getters.viewCustomerPermission
    },
    createEntityPermission () {
      return this.$store.getters.createEntityPermission
    },
    viewEntityPermission () {
      return this.$store.getters.viewEntityPermission
    },
    assignToCustomerPermission () {
      return this.$store.getters.assignToCustomerPermission
    },
    updateEntityPermission () {
      return this.$store.getters.updateEntityPermission
    },
    viewQuotesAndProjectsPermission () {
      return this.$store.getters.viewQuotesAndProjectsPermission
    },
    viewSupportPermission () {
      return this.$store.getters.viewSupportPermission
    },
    viewRolePermission () {
      return this.$store.getters.viewRolePermission
    },
    viewPermissionPermission () {
      return this.$store.getters.viewPermissionPermission
    },
    viewAreasPermission () {
      return this.$store.getters.viewAreasPermission
    },
    viewCommunicateServicePermission () {
      return this.$store.getters.viewCommunicateServicePermission
    },
    viewConnectServicePermission () {
      return this.$store.getters.viewConnectServicePermission
    },
    viewSecureServicePermission () {
      return this.$store.getters.viewSecureServicePermission
    },
    createCustomerPermission () {
      return this.$store.getters.createCustomerPermission
    },
    editCustomerPermission () {
      return this.$store.getters.editCustomerPermission
    },
    uploadDashboardImage () {
      return this.$store.getters.uploadDashboardImage
    },
    deleteEntityPermission () {
      return this.$store.getters.deleteEntityPermission
    },
    maintelTesting () {
      return this.$store.getters.maintelTesting
    }
  },
  watch: {
    $route (to, from) {
      if (to.hash !== undefined || to.hash !== '') {
        var refName = to.hash.substring(1)
        this.gotoanchor(refName)
      }
    },
    search () {
      const helpSections = this.$refs['helpcontainer'].children

      for (var i = 0; i < helpSections.length; i++) {
        if (helpSections[i] && helpSections[i].innerHTML && helpSections[i].innerHTML.toLowerCase().indexOf(this.search.toLowerCase()) === -1) {
          helpSections[i].style.display = 'none'
        } else {
          helpSections[i].style.display = ''
        }
      }
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') {
        return
      }

      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },

    gotoanchor (refName) {
      const id = refName
      const yOffset = -60
      const element = document.getElementById(id)
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

      this.jumptoopen = false

      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }
}
</script>
<style>
  .jumpto-list .v-list__tile {
    height: 280px !important;
  }
</style>
<style scoped>
  .container-margin {
      margin-top: -0.5rem;
      margin-bottom: 2rem;
  }

  .style-search {
      margin: -10px 20px 5px 20px;
  }

  .group-jumpto {
      width: 17rem;
      margin: 0 0 -0.8rem -0.5rem;
      padding: 0;
      border-bottom: none;
  }

  .card-jumpto {
      margin-top: -0.7rem;
      float: right;
      width: 20rem;
  }

  .item-jumpto {
      color: rgb(99, 180, 82);
      cursor: pointer;
      height: 20px;
  }

  .v-list__tile__content {
    display: block;
    overflow: auto;
  }
</style>
